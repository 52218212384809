import React from "react";
import { Link } from "gatsby";

import undrawScience from "../../assets/image/svg/undraw_science.svg";

const Hero = ({ className, ...rest }) => {
  return (
    <>
      <div className={className} {...rest}>
        <div className="container">
          <div className="row justify-content-center">
            {/* <!-- Hero Content --> */}
            <div
              className="col-xl-5 col-lg-6 col-md-8 col-xs-10 order-2 order-lg-1"
              data-aos="fade-right"
              data-aos-delay="500"
            >
              <div className="pr-lg-10 pr-xl-0 pt-0 pt-lg-0 pb-13 pb-lg-0">
                <h1 className="font-size-12 mb-8">PCR a Domicilio</h1>
                <p className="font-size-7 mb-0 heading-default-color">
                  Tomamos tu muestra y entregamos tu
                  <br className="d-none d-md-block" />
                  resultado rápidamente, con mínimo contacto y desde la
                  seguridad de tu hogar.
                </p>
                <div className=" pt-5 pt-lg-10">
                  <a
                    href="https://midiagnostico.cl/#agenda"
                    className="btn btn-dodger-blue-2 rounded-5"
                  >
                    Agenda con Nosotros
                  </a>
                  <span className="d-block pt-5 pt-lg-9 heading-default-color">
                    ¿Necesitas agendar como grupo, empresa o u otra institución
                    (más de 1 persona)?{" "}
                    <Link to={"/contacto"}>Contáctanos</Link>
                  </span>
                </div>
              </div>
            </div>
            {/* <!-- End Hero Content --> */}
            {/* <!-- l2-hero-image-group --> */}
            <div
              className="col-xl-7 col-lg-6 col-md-7 order-1 order-lg-2 d-none d-md-block"
              data-aos="fade-left"
              data-aos-delay="800"
            >
              <div className="l2-hero-image-group mt-5 mt-lg-0 ">
                <div className="img-2">
                  <img src={undrawScience} alt="" style={{ maxHeight: 600 }} />
                </div>
                {/* <div className="img-3">
                  <img src={undrawMedicine} style={{ maxHeight: 600 }} />
                </div> */}
                {/* <!-- Image Group Content --> */}
                {/* <div className="img-group-content bg-white absolute-center shadow-4 max-w-193">
                  <div className="seciton-body">
                    <div className="mb-7">
                      <h5 className="font-size-6 font-weight-medium mb-0 line-height-reset text-dark-cloud">
                        Meeting with John
                      </h5>
                      <p className="font-size-3 text-stone">
                        11:15am, Sep 10, Sunday
                      </p>
                    </div>
                    <h6 className="font-size-3 text-stone">Agenda:</h6>
                    <p className="font-size-3 text-dark-cloud">
                      How to get more customers for your ecommerce business
                    </p>
                  </div>
                  <div className="d-flex align-items-center w-100">
                    <Link to={"#"} className="btn-buttercup w-50 text-white">
                      <i className="fa fa-calendar font-size-3 mr-1"></i>
                      Reschedule
                    </Link>
                    <Link to={"#"} className="btn-red w-50">
                      <i className="fa fa-times font-size-3 mr-1"></i> Cancel
                    </Link>
                  </div>
                </div> */}
                {/* <!-- End Image Group Content --> */}
              </div>
            </div>
            {/* <!-- End l2-hero-image-group --> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
