import { Link } from "gatsby";
import React from "react";

const Pricing = () => {
  return (
    <div className="pb-5 pb-md-11 pb-lg-19">
      <div className="container">
        <div className="row justify-content-center">
          {/* Single Table */}
          <div className="col-xl-4 col-lg-5 col-md-6 col-sm-8">
            <div
              className="border rounded-10 text-center px-7 px-lg-16 pt-10 pb-13 gr-hover-2 mb-9"
              data-aos="fade-up"
              data-aos-duration={300}
              data-aos-once="true"
            >
              <p className="text-dodger-blue-1 font-size-5 mb-9">1 Persona</p>
              <h2 className="font-size-11 mb-1">$40.000</h2>
              <span className="font-size-5 mb-2">Paciente único</span>
              <ul className="list-unstyled font-size-5 line-height-50 heading-default-color pt-8">
                <li className="mb-7">La solución rápida y sencilla</li>
                <li className="mb-7">Ideal para viajeros</li>
                {/* 
                <li className="mb-7">Blog Tools</li>
                <li className="mb-7 text-stone">
                  <del>eCommerce Store</del>
                </li> */}
              </ul>
              <div className="pt-7 pt-lg-17">
                <a
                  href="https://midiagnostico.cl/#agenda"
                  className="btn btn-dodger-blue-2 rounded-5"
                >
                  Agenda directamente online
                </a>
              </div>
            </div>
          </div>
          {/* End Single Table */}
          {/* Single Table */}
          <div className="col-xl-4 col-lg-5 col-md-6 col-sm-8">
            <div
              className="border rounded-10 text-center px-7 px-lg-16 pt-10 pb-13 gr-hover-2 mb-9"
              data-aos="fade-up"
              data-aos-duration={600}
              data-aos-once="true"
            >
              <p className="text-dodger-blue-1 font-size-5 mb-9">
                2-14 Personas
              </p>
              <h2 className="font-size-11 mb-1">$30.000</h2>
              <span className="font-size-5 mb-2">Por paciente</span>
              <ul className="list-unstyled font-size-5 line-height-50 heading-default-color pt-8">
                <li className="mb-7">25% de descuento</li>
                <li className="mb-7">Ideal para grupos familiares</li>
                {/* 
                <li className="mb-7">Blog Tools</li>
                <li className="mb-7">eCommerce Store</li> */}
              </ul>
              <div className="pt-7 pt-lg-17">
                <a
                  href="https://midiagnostico.cl/#agenda"
                  className="btn btn-dodger-blue-2 rounded-5"
                >
                  Agenda directamente online
                </a>
              </div>
            </div>
          </div>
          {/* End Single Table */}
          {/* Single Table */}
          <div className="col-xl-4 col-lg-5 col-md-6 col-sm-8">
            <div
              className="border rounded-10 text-center px-7 px-lg-16 pt-10 pb-13 gr-hover-2 mb-9"
              data-aos="fade-up"
              data-aos-duration={600}
              data-aos-once="true"
            >
              <p className="text-dodger-blue-1 font-size-5 mb-9">
                15+ Personas
              </p>
              <h2 className="font-size-11 mb-1">$25.000</h2>
              <span className="font-size-5 mb-2">Por paciente</span>
              <ul className="list-unstyled font-size-5 line-height-50 heading-default-color pt-8">
                <li className="mb-7">Operativos instituciones</li>
                <li className="mb-7">Ideal para empresas</li>
                {/* 
                <li className="mb-7">Blog Tools</li>
                <li className="mb-7">eCommerce Store</li> */}
              </ul>
              <div className="pt-7 pt-lg-17">
                <Link
                  to={"/contacto"}
                  className="btn btn-dodger-blue-2 rounded-5"
                >
                  Contáctanos para agendar
                </Link>
              </div>
            </div>
          </div>
          {/* End Single Table */}
          {/* Single Table */}
          <div className="col-xl-4 col-lg-5 col-md-6 col-sm-8">
            <div
              className="border rounded-10 text-center px-7 px-lg-16 pt-10 pb-13 gr-hover-2 mb-9"
              data-aos="fade-up"
              data-aos-duration={600}
              data-aos-once="true"
            >
              <p className="text-dodger-blue-1 font-size-10 mb-9">Antígeno</p>
              <h2 className="font-size-11 mb-1">$25.000</h2>
              <span className="font-size-5 mb-2">Por paciente</span>
              <ul className="list-unstyled font-size-5 line-height-50 heading-default-color pt-8">
                <li className="mb-7">Test rápido y eficiente</li>
                <li className="mb-7">Resultados en 15 minuto</li>
                {/* 
                <li className="mb-7">Blog Tools</li>
                <li className="mb-7">eCommerce Store</li> */}
              </ul>
              <div className="pt-7 pt-lg-17">
                <a
                  href="https://midiagnostico.cl/#agenda"
                  className="btn btn-dodger-blue-2 rounded-5"
                >
                  Agenda directamente online
                </a>
              </div>
            </div>
          </div>
          {/* End Single Table */}
        </div>
      </div>
    </div>
  );
};

export default Pricing;
