import React from "react";

import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/landing/Hero";
import Progress from "../sections/landing/Progress";
import Pricing from "../sections/pricing/Pricing";

const Landing = () => {
  return (
    <>
      <PageWrapper
        themeConfig={{
          headerClassName: "site-header--menu-right",
          // headerButton: (
          //   <>
          //     <button className="btn btn btn-dodger-blue-2 header-btn rounded-5">
          //       Get Started for Free
          //     </button>
          //   </>
          // ),
          footerStyle: "style2",
        }}
      >
        <Hero className="pt-11 pt-lg-34 pb-8 pb-lg-33" />
        <Pricing />
        <Progress className="pb-lg-21 border-top border-default-color-1" />
        <div id="precios" className="mb-20"></div>
        {/* <iframe
          id="agenda"
          title="Agenda MiDiagnostico"
          width="100%"
          height="750px"
          src="https://agenda.midiagnostico.cl/portal-embed#/customer/4124910000000026057"
          frameBorder="0"
          allowFullScreen={false}
        >
          {" "}
        </iframe> */}

        {/* <Hero className="pt-11 pt-lg-34 pb-8 pb-lg-33" />
        <Counter className="pt-lg-5 pb-8 pb-lg-27" />
        
        <Content1 className="pb-10 pb-sm-5 pb-lg-14" />
        <Content2 className="pb-0 pb-lg-31" />
        <Content3 className="pb-12 pb-lg-25" />
        <Testimonial className="pt-lg-19 pb-12 pb-lg-17" />
        <CTA className="bg-images" /> */}
      </PageWrapper>
    </>
  );
};
export default Landing;
